<template>
  <div class="mt-10 flex flex-col items-center justify-center">
    <div
      v-if="!isNetworkActive"
      class="flex size-16 items-center justify-center rounded-full bg-red-100/10"
    >
      <WifiIcon class="size-10 text-red-500" />
    </div>
    <div
      v-else
      class="flex h-16 w-16 items-center justify-center rounded-full bg-red-500/10"
    >
      <ExclamationIcon class="size-10 text-red-500" />
    </div>
    <h2 class="mt-5 max-w-[200px] text-center text-sm">
      {{
        !isNetworkActive
          ? "You're offline, check your internet connection"
          : message || 'Something went wrong, try again'
      }}
    </h2>
    <UiButton rounded center size="sm" class="mt-5" @click="$emit('tryAgain')">
      Retry
    </UiButton>
  </div>
</template>

<script setup lang="ts">
import WifiIcon from '@/assets/icons/wifi.svg?component'
import ExclamationIcon from '@/assets/icons/exclamation.svg?component'
import { Network } from '@capacitor/network'
import { Capacitor } from '@capacitor/core'

const { message = '' } = defineProps<{
  message?: string
}>()

const emits = defineEmits(['tryAgain'])

// Reusable composable
const { isOnline } = useNetwork()

watch(isOnline, (val) => {
  if (val) {
    emits('tryAgain')
  }
})

// Capacitor
const isConnected = ref(true)
Network.addListener('networkStatusChange', (status) => {
  isConnected.value = status.connected
  if (isConnected.value) {
    emits('tryAgain')
  }
})

const isNetworkActive = computed(() => {
  if (Capacitor.getPlatform() !== 'web') {
    return isConnected.value
  } else {
    return isOnline.value
  }
})

onUnmounted(() => {
  Network.removeAllListeners()
})
</script>
